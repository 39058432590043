<template> 
	<v-card dense> 
		<v-row style="margin: auto">
			<v-col cols="12">
				<b>Para seleccionar una opcion, Pulse el boton: </b>
			</v-col>
			<v-col cols="12" lg="3" md="3" v-for="col in items" :key="col.DedID">
				<v-card>
					<v-btn
						style="   
                        margin: auto;
                        display: flex;
                        justify-content: space-evenly;"
						color="warning"
						@click="click(col)" >
						<span class="white--text ">
                            {{ col.ChaDescription }}
                        </span>
                        -
                        <span>
                            {{ col.TypeFreshProductionDestinationText }}
                        </span>
					</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-card> 
</template>

<script>

    import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";

	export default {
		components: {},

		data() {
			return {
				radios: null,
				items: [],
			};
		},

		mounted() {

            _sPrfChamberService.list({typedestiny:1},this.$fun.getUserID())
                .then(r => {
                    console.log(r.data);
                   this.items = r.data;
                })
        },
		methods: {
			change(val) {
				this.items = val;
			},
			 
			click(val) {
				this.$emit("click", val);
			},
		},
	};
</script>
