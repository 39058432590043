<template>
	<v-card dense>
		<v-col>
		<!-- <s-toolbar
			dark
			color="#BDBDBD"
			close
			@close="closeDialogAdmin()"
		></s-toolbar> -->

		<v-row>
			<v-col>
				<s-crud
					:config="config"
					:filter="filter"
					close
					title="Pallets en cámara"
					@close="closeDialogAdmin()"
					ref="listPallet"
				>
					<template v-slot:filter>
						<v-container>
							<v-row>
								<v-col>
									<s-select-customer 
										clearable 
										label="Cliente" 
										v-model="filter.UwoCustomerID"
									></s-select-customer>
								</v-col>
							</v-row>
						</v-container>
					</template>
					<template v-slot:PltID="{ row }">
						 {{row.PltID + "-" + row.PltCorrelative}}
					</template>
					<template v-slot:CocChange="{ row }">
						<v-btn
							color="primary"
							x-small
							rounded
							@click="clickCocChange(row)"
							>
							<i class="fas fa-edit"></i>
							</v-btn>
					</template>
					<template v-slot:CocDateBegin="{ row }">
						<v-btn
							color="primary"
							x-small
							rounded
							@click="clickCocDateBegin(row)"
						>
						<i class="far fa-calendar-times"></i>
						<!--<i class="far fa-calendar-alt"></i>
						<i class="far fa-clock"></i>-->
							
						</v-btn>
					</template>
				</s-crud>
			</v-col>
		</v-row>
		</v-col>
		<v-dialog 
			v-if="dialogChange"
			v-model="dialogChange"
			persistent
			hide-overlay
			width="60%">
			<v-card>
				<supply-chamber-change :chaID="this.chamberSelected.ChaID" :PltID="pltID" @EndProcess="endProcessChange($event)"></supply-chamber-change>
			</v-card>
		</v-dialog>

		<v-dialog 
			v-if="dialogTimeChange"
			v-model="dialogTimeChange"
			persistent
			hide-overlay
			width="60%">
			<v-card>
				<registration-time-change :CocDateBeginR="cocDateBegin" :CocID="cocID"  @EndProcess="endProcessChange($event)" ></registration-time-change>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import sSelectCustomer from "@/components/Sale/SselectClientSap.vue";

	import _sPrfCoolingChamber from "@/services/FreshProduction/PrfCoolingChamberService";
	import SupplyChamberChange from "./SupplyChamberChange.vue";
	import RegistrationTimeChange from "./RegistrationTimeChange.vue";

	export default {
		components: {
			sSelectCustomer,
			SupplyChamberChange,
			RegistrationTimeChange
		},

		props: {
			chamberSelected: null
		},

		data() {
			return {
				config: {
					model: {
						PltID: "ID",
						CocChange: "",
						CocDateBegin: ""
					},
					service: _sPrfCoolingChamber,
					headers: [
						{text:"Pallet", value: "PltID"},
						{text:"Horas Dentro", value: "TimeInside"},
						{text:"Cliente", value: "CardName"},
						{text:"Calibre", value: "TypeCaliberName"},
						{text:"Presentación", value: "PresentationName"},
						{text:"Cultivo", value: "TypeCultiveName"},
						{text:"Tipo Cultivo", value: "TypeCropName"},
						{text:"Variedad", value: "VarietyName"},
						{text:"Fila", value: "ChrDescription"},
						{text:"Carril", value: "ChpPosition"},
						{text:"Columna", value: "ChcDescription"},
						{text:"Cambiar", value: "CocChange"},
						{text:"Editar Hora y Fecha", value: "CocDateBegin"},
					]
				},
				filter:{
					ChaID: this.chamberSelected.ChaID
				},				
				cocID: 0,			
				cocDateBegin: "",
				dialogChange: false,
				dialogTimeChange: false,
			}
		},

		methods: {
			endProcessChange(coc){
				this.dialogChange = false;
				this.dialogTimeChange = false;
				if (coc != null) {
					this.$refs.listPallet.refresh();
				}
			},
			closeDialogAdmin() {
				this.$emit("closeDialogAdmin")
			},
			clickCocChange(item){
				console.log(item);
				this.pltID = item.PltID;
				this.dialogChange = true;
			},

			clickCocDateBegin(item){
				console.log(item);				
				this.cocID = item.CocID;	
				this.cocDateBegin = this.$moment(new Date(item.CocDateBegin)).format("DD-MM-YYYY HH:mm:SS");
				this.dialogTimeChange = true;
			}
		},

		created () {
			console.log('chamberSelected ', this.chamberSelected);
		},
	}
</script>

<style scoped>

</style>